<template>
    <div class="card ribbon-box border shadow-none mb-lg-0" style="margin-bottom:0;">
      <div class="card-header align-items-center d-flex card-primary">
        <h4 class="card-title mb-0 flex-grow-1">{{ $t("t-bot-strategy1-name") }}</h4>
      </div>
      <div class="card-body text-muted">
        <ul class="mb-0" v-if="currentLocale() === 'en'">
          <li><b>For Whom:</b> Suitable for investors seeking long-term reliability and minimal risks.</li>
          <li><b>Approach:</b> Transactions are closed according to the initial profit target; in market downturns, averaging reduces the average price of the asset, thereby increasing the final profit.</li>
          <li><b>Result:</b> Fewer transactions, with higher profit per transaction.</li>
        </ul>
        <ul class="mb-0" v-if="currentLocale() === 'ru'">
          <li><b>Для кого:</b> Подходит для инвесторов, ищущих долгосрочную надежность и минимальные риски.</li>
          <li><b>Подход:</b> Закрытие сделок происходит по изначальной цели прибыли, при просадке рынка усреднения уменьшают среднюю цену актива, при этом увеличивая итоговую прибыль.</li>
          <li><b>Результат:</b> Меньшее количество сделок, с более высокой прибылью по сделке.</li>
        </ul>
        <br>
        <div class="align-middle text-center">
          <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#add-bot" @click="selectStrategy">
            <i class=" ri-add-circle-line me-1 align-bottom"></i>
            {{ $t("t-bot-choose-button") }}
          </button>
        </div>
      </div>
    </div>
</template>
<style>
.ribbon-three::after {
  border-left: 64px solid transparent;
  border-right: 64px solid transparent;
}

.ribbon-three > span {
  width: 130px;
}
</style>
<script>
import {localeComputed} from "@/state/helpers";
import {PredefinedStrategies} from "@/state/enum/predefined-strategies.ts";


export default {
  data() {
    return {
      predefine_strategy: PredefinedStrategies.conservative,
    };
  },
  components: {
    ...localeComputed
  },

  methods: {
    selectStrategy() {
      this.$emit('select-strategy');
    },
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  computed: {
  },
};
</script>
