<template>
    <form v-on:submit.prevent="submit">
      <div class="row g-3">
        <div class="col-xxl-12">
          <label for="trading-platform" class="form-label">{{ $t("t-api-key-trading-platform") }} *</label>
          <select
              required
              class="form-select mb-3"
              v-model="form.type"
              id="trading-platform"
          >
            <option value="" selected disabled hidden>{{ $t("t-api-key-choose-trading-platform") }}</option>
            <option value="binance-spot">Binance spot</option>
<!--            <option value="binance-futures">Binance futures</option>-->
            <option value="bitget-spot">Bitget spot</option>
<!--            <option value="bitget-futures">Bitget futures</option>-->
          </select>
        </div>
        <span v-if="this.form.type === 'binance-spot'">
          <div class="alert alert-info alert-dismissible alert-border-left" role="alert">
            <i class="ri-alert-line me-3 align-middle fs-16"></i>
            {{$t('t-wizard-step3-setup-video-instruction')}} - <a href="https://www.youtube.com/watch?v=44mS-qbDnb8&t=5s" target="_blank">{{$t('t-link')}}</a>
            <button type="button" class="btn-close" aria-label="Close" data-bs-dismiss="alert"></button>
          </div>
        </span>
        <div class="col-xxl-12">
          <div>
            <label for="title" class="form-label">{{ $t("t-api-key-name") }} *</label>
            <input type="text" required class="form-control" id="title" v-model="form.title"
                   :placeholder='$t("t-api-key-name-tip")' />
          </div>
        </div>
        <div class="col-xxl-12">
          <div>
            <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show" role="alert">
              <i class="las la-info-circle label-icon"></i>
              {{ $t("t-api-key-hint") }} <a href="/documents" target="_blank">{{ $t("t-documents") }}</a>
              <button type="button" class="btn-close" aria-label="Close" data-bs-dismiss="alert"></button>
            </div>
            <label for="api_key" class="form-label">{{ $t("t-api-key-single-key") }} *</label>
            <input type="text" required class="form-control" id="api_key" v-model="form.api_key"/>
          </div>
        </div>
        <!--end col-->
        <div class="col-xxl-12">
          <div>
            <label for="secret_key" class="form-label">{{ $t("t-api-key-secret-key") }} *</label>
            <input type="text" required class="form-control" id="secret_key" v-model="form.secret_key"/>
          </div>
        </div>

        <div class="col-xxl-12" v-if="form.type === 'bitget-futures' || form.type === 'bitget-spot'">
          <div>
            <label for="secret_key" class="form-label">{{ $t("t-api-key-password") }} *</label>
            <input type="text" required class="form-control" id="password" v-model="form.password"/>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="hstack gap-2 justify-content-end">
<!--                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">{{ $t("t-close-button") }}</button>-->
            <button type="submit" class="btn btn-info" @click="action='test'">{{ $t("t-validate-button") }}</button>
            <button type="submit" class="btn btn-primary" :class="{disabled: !active_save_btn}" @click="action='save'">{{ $t("t-create-button") }}</button>
          </div>
        </div>
      </div>
    </form>
</template>

<script>
import ExchangeConnectionService from "@/services/exchange-connection-service";
import ExchangeConnection from "@/state/entities/exchangeConnection";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      action: null,
      active_save_btn: false,
      form: {
        type: '',
        title: '',
        api_key: '',
        secret_key: '',
        password: '',
      }
    }
  },
  components: {},
  mounted() {
    this.clearForm();
  },
  methods: {
    clearForm() {
      this.active_save_btn = false;
      this.form.secret_key = null;
      this.form.password = null;
      this.form.api_key = null;
      this.form.title = null;
    },
    submit() {
      if(this.action === 'test') {
        this.checkCredentials();
      }
      if(this.action === 'save') {
        this.addApiKey();
      }
    },
    addApiKey() {
      ExchangeConnectionService.create(this.form).then(async (response) => {
        if (response.status === 201) {
          this.$emit('created');
          (await ExchangeConnection.api().get('/exchange-connection'));
          Swal.fire({
            title: this.$t("t-token-created"),
            icon: 'success',
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          /** @TODO: error handling.Add message from server */
          Swal.fire({
            title: this.$t("t-default-error-response-message"),
            icon: 'error',
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }).catch((er) => {
        console.log(er);
        /** @TODO: error handling.Add message from server */
        Swal.fire({
          title: this.$t("t-default-error-response-message"),
          icon: 'error',
          timer: 3000,
          timerProgressBar: true,
        });
      }).finally(() => {
        this.clearForm();
      });
    },
    checkCredentials() {
      ExchangeConnectionService.test(this.form).then(async (response) => {
        if (response.data.result && response.data.can_trade) {
          this.active_save_btn = true;
          Swal.fire({
            title: this.$t("t-token-validation-success"),
            icon: 'success',
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          /** @TODO: error handling.Add message from server */
          Swal.fire({
            title: this.$t("Problem with token: (" + response.data.error_message + ")"),
            icon: 'error',
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }).catch((error) => {
        console.log(error);
        /** @TODO: error handling.Add message from server */
        Swal.fire({
          title: this.$t("t-default-error-response-message"),
          icon: 'error',
          timer: 3000,
          timerProgressBar: true,
        });
      });
    }
  },
};
</script>
