<template>
    <div class="card ribbon-box border shadow-none mb-lg-0" style="margin-bottom:0;">
      <div class="card-header align-items-center d-flex card-warning">
        <h4 class="card-title mb-0 flex-grow-1">{{ $t("t-bot-strategy2-name") }}</h4>
      </div>
      <div class="card-body text-muted">
        <ul class="mb-0" v-if="currentLocale() === 'en'">
          <li><b>For Whom:</b> Ideal for investors ready for mid-term investments with active engagement.</li>
          <li><b>Approach:</b> Transactions are closed at the asset's average price, allowing for more active utilization of all market opportunities.</li>
          <li><b>Result:</b> A higher number of transactions with smaller average profit per transaction.</li>
        </ul>
        <ul class="mb-0" v-if="currentLocale() === 'ru'">
          <li><b>Для кого:</b> Идеально для инвесторов, готовых к среднесрочным вложениям с активным участием.</li>
          <li><b>Подход:</b> Закрытие сделок происходит от средней цены актива, что позволяет более активно использовать все рыночные возможности.</li>
          <li><b>Результат:</b> Большее количество сделок, с меньшей средней прибылью по сделке</li>
        </ul>
        <br>
        <div class="align-middle text-center">
          <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#add-bot"
                  @click="selectStrategy">
            <i class=" ri-add-circle-line me-1 align-bottom"></i>
            {{ $t("t-bot-choose-button") }}
          </button>
        </div>
      </div>
    </div>
</template>
<style>
.ribbon-three::after {
  border-left: 64px solid transparent;
  border-right: 64px solid transparent;
}

.ribbon-three > span {
  width: 130px;
}
</style>
<script>
import {localeComputed} from "@/state/helpers";
import {PredefinedStrategies} from "@/state/enum/predefined-strategies.ts";


export default {
  data() {
    return {
      predefine_strategy: PredefinedStrategies.moderate,
      rows: [],
    };
  },
  components: {
    ...localeComputed
  },

  methods: {
    selectStrategy() {
      this.$emit('select-strategy');
    },
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  computed: {
  },
};
</script>
