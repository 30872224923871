<template>
  <div class="tab-pane fade" id="register-exchange-step" role="tabpanel" aria-labelledby="register-exchange-step-tab">
    <form>
      <div>
        <div class="mb-4">
          <div>
            <h5 class="mb-1">{{ $t("t-lets-create-an-exchange-account") }}</h5>
            <p class="text-muted">{{ $t("t-use-instruction-below") }}</p>

            <div class="col-12 mb-5">
              <span class="border border-2 me-4 p-2">
                   <a href="https://www.binance.com/en" target="_blank">
                     <img src="@/assets/images/integrations/binance.png" height="30">
                   </a>
                </span>
                <p class="text-muted">
                  <a href='https://www.binance.com/en/support/faq/how-to-register-on-binance-website-08f314ad219e45fc9b7a27b67801a5c4' target="_blank">
                    <i class="mdi mdi-file-document"></i>&nbsp;
                    <span>{{ $t("t-wizard-step2-how-to-register") }}</span>
                  </a>
                  <br>
                  <a href='https://www.binance.com/en/support/faq/how-to-complete-entity-verification-on-binance-a-step-by-step-guide-360015552032' target="_blank">
                    <i class="mdi mdi-file-document"></i>&nbsp;
                    <span>{{ $t("t-wizard-step2-how-to-complete-verification") }}</span>
                  </a>
                </p>
            </div>


            <div class="col-12 mb-5">
                <span class="border border-2 me-4 p-2">
                  <a href="https://www.bitget.com/" target="_blank">
                    <img src="@/assets/images/integrations/bitget.png" height="30">
                  </a>
                </span>
                <p class="text-muted">
                  <a href='https://www.binance.com/en/support/faq/how-to-register-on-binance-website-08f314ad219e45fc9b7a27b67801a5c4' target="_blank">
                    <i class="mdi mdi-file-document"></i>&nbsp;
                    <span>{{ $t("t-wizard-step2-how-to-register") }}</span>
                  </a>
                </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-6 text-start">
            <button class="btn btn-success btn-md btn-label left ms-auto previestab" type="button" data-previous="choose-exchange-step-tab" id="previous_step_1">
              <div class="btn-content">
                <i class="ri-arrow-left-line label-icon align-middle fs-16"></i>
                {{ $t("t-back") }}
              </div>
            </button>
          </div>
          <div class="col-6 text-end">
            <button class="btn btn-success btn-md btn-label right ms-auto nexttab nexttab" type="button" data-nexttab="add-api-key-tab" id="step2">
              <div class="btn-content">
                <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                {{ $t("t-continue") }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<style>
.ribbon-three::after {
  border-left: 64px solid transparent;
  border-right: 64px solid transparent;
}

.ribbon-three > span {
  width: 130px;
}
</style>
<script>
import {localeComputed} from "@/state/helpers";

export default {
  data() {
    return {
    };
  },
  components: {
    ...localeComputed
  },
  methods: {
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  computed: {
  },
  mounted() {
  }
};
</script>