<template>
  <form v-on:submit.prevent="submit">
    <div class="row g-3">
      <div class="col-xxl-12">
        <div>
          <label for="name" class="form-label">{{ $t('t-bot-bot-name') }}</label>
          <input required type="text" class="form-control" id="name" :placeholder='$t("t-example") + " My trading bot John"' v-model="form.title"/>
        </div>
      </div>
      <div class="col-xxl-12">
        <label for="api_key_id" class="form-label">{{ $t('t-api-key-title') }}</label>
        <select
            required
            class="form-select"
            aria-label="Default select example"
            v-model="form.exchange_connection_id"
            @change="defineCurrency($event)"
        >
          <option value="">{{ $t('t-bot-link-your-api-key') }}</option>
          <option v-for="(credential) of credentials" :key="credential.$id" :value="credential.$id" :data-type="credential.type">{{credential.title}}</option>
        </select>
      </div>

      <div class="col-xxl-12" v-show="! displayDetails">
        <div>

          <label for="initial-balance" class="form-label">{{ $t('t-bot-create-form-initial-balance') }} {{baseCurrency}}</label>
          <div class="input-group">
            <input type="text" class="form-control" :placeholder='$t("t-example") + " 60"' v-model="form.balance">
            <button class="btn btn-success" type="button" v-on:click="applyBalance">{{ $t("t-apply") }}</button>
          </div>
          <div class="form-text">
            {{ $t('t-minimum') }}: 60
          </div>
        </div>
      </div>
      <div class="col-xxl-12" v-show="displayDetails">
        <div>
          <label for="first_order_amount" class="form-label">{{ $t('t-bot-first-order-amount') }} {{baseCurrency}}</label>
          <input type="number" required class="form-control" min="20" id="first_order_amount" :placeholder='$t("t-example") + " 20"' v-model="form.first_order_amount" />
          <div class="form-text">
            {{ $t('t-minimum') }}: 20
          </div>
        </div>
      </div>
      <div class="col-xxl-12" v-show="displayDetails">
        <div>
          <label for="take-profit" class="form-label">{{ $t('t-bot-active-deals') }}</label>
          <input type="number" required class="form-control" id="take-profit" max="13" :placeholder='$t("t-example") + " 5"' v-model="form.active_deals" />
        </div>
      </div>

      <div class="col-xxl-12" v-show="displayDetails">
        <div>
          <label for="take-profit" class="form-label">{{ $t('t-bot-required-balance') }} {{baseCurrency}}</label>
          <input type="text" disabled class="form-control" id="take-profit"  placeholder="" :value="requiredBalance" />
        </div>
      </div>

      <!--end col-->
      <div class="col-lg-12" v-show="displayDetails">
        <div class="hstack gap-2 justify-content-end">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">{{ $t('t-close-button') }}</button>
          <button type="submit" class="btn btn-primary">{{ $t('t-create-button') }}</button>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </form>
</template>

<script>
import ExchangeConnection from "@/state/entities/exchangeConnection";
import BotService from "@/services/bot-service";
import Bot from "@/state/entities/bot";
import Swal from "sweetalert2";
import {PredefinedStrategies} from "@/state/enum/predefined-strategies.ts";

export default {
  props: ['predefine_strategy'],
  data() {
    return {
      displayDetails: false,
      form: {
        balance: 0,
        title: '',
        exchange_connection_id: '',
        first_order_amount: '',
        take_profit_type: 'percentage',
        active_deals: '',
        base_currency: 'USDT'
      }
    }
  },
  computed: {
    requiredBalance() {
      if(!this.form.active_deals || !this.form.first_order_amount){
        return "";
      }
      if(this.predefine_strategy === PredefinedStrategies.conservative || this.predefine_strategy === PredefinedStrategies.moderate) {
        return this.form.active_deals * this.form.first_order_amount * (2+1);
      }
      return '';
    },
    credentials() {
      return ExchangeConnection.all().filter((credential) => {
        if(this.predefine_strategy === PredefinedStrategies.conservative || this.predefine_strategy === PredefinedStrategies.moderate) {
          return credential.type === "binance-spot" || credential.type === "bitget-spot"
        }
      });
    },
    baseCurrency() {
      return this.form.base_currency;
    },
  },
  components: {},
  async mounted() {
    (await ExchangeConnection.api().get('/exchange-connection'));
  },
  methods: {
    clearForm() {
        this.displayDetails = false;
        this.form.balance = null;
        this.form.title = '';
        this.form.exchange_connection_id = '';
        this.form.first_order_amount = '';
        this.form.take_profit_type = '';
        this.form.active_deals = '';
        this.form.base_currency = 'USDT';
    },
    applyBalance() {
      let balance = parseInt(this.form.balance);
      if(balance < 60) {
        Swal.fire({
          title: this.$t("t-minimum-balance-should-be-60"),
          icon: 'error',
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }
      // 60 and less
      // 120 - 179
      // 180 - 239
      // 240 - 299
      // 300 - 359
      // 360 - 419
      // 420 - 479
      // 480 - 539
      // 540 - 599
      // 600 and more
      switch (true) {
        case balance < 120:
          this.form.active_deals = 1;
          break;
        case balance < 180:
          this.form.active_deals = 2;
          break;
        case balance < 240:
          this.form.active_deals = 3;
          break;
        case balance < 300:
          this.form.active_deals = 4;
          break;
        case balance < 360:
          this.form.active_deals = 5;
          break;
        case balance < 420:
          this.form.active_deals = 6;
          break;
        case balance < 480:
          this.form.active_deals = 7;
          break;
        case balance < 540:
          this.form.active_deals = 8;
          break;
        case balance < 600:
          this.form.active_deals = 9;
          break;
        default:
          this.form.active_deals = 10;
          break;
      }
      this.form.first_order_amount = parseInt(balance / (this.form.active_deals * (2+1)));
      this.displayDetails = true;
    },
    defineCurrency() {
      this.form.base_currency = 'USDT';
    },
    submit() {
      const payload = { ...this.form };
      payload.exchange_connection_id = parseInt(payload.exchange_connection_id);
      payload.first_order_amount = parseInt(payload.first_order_amount);
      payload.active_deals = parseInt(payload.active_deals);
      payload.take_profit_type = 'percentage_from_total_volume'
      payload.base_currency = this.baseCurrency;
      payload.take_profit = 4;
      payload.enable_safety_orders = true;
      payload.safety_order_percentage = 15;
      payload.max_safety_orders = 2;
      payload.preset = this.predefine_strategy;

      payload.safety_order_amount = payload.first_order_amount;
      payload.stop_lose_percentage = null;
      BotService.create(payload).then((response) => {
        this.$emit('created');
        if(response.status === 201) {
          (Bot.api().get('/bot'));
          Swal.fire({
            title: "Bot successfully created.",
            icon: 'success',
            timer: 3000,
            timerProgressBar: true,
          });
          /** @TODO: error handling.Add message from server */
          console.log(response);
        } else {
          Swal.fire({
            title: this.$t("t-default-error-response-message"),
            icon: 'error',
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }).catch((er) => {
        console.warn(er);
        /** @TODO: error handling.Add message from server */
        Swal.fire({
          title: this.$t("t-default-error-response-message"),
          icon: 'error',
          timer: 3000,
          timerProgressBar: true,
        });
      });
    },
  },
};
</script>
