<template>
<!--  <Layout>-->
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">

        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>
    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">

        <div class="row">
          <div class="offset-md-2 col-md-8 col-xs-12">
            <div class="text-end">
              <a href="/dashboard" class="btn btn-warning text-black-50">{{ $t("t-i-will-do-it-later") }}</a>
            </div>
          </div>
          <div class="offset-md-2 col-md-8 col-xs-12">
            <div class="text-center mb-4 text-white-50">
              <div>
                <span class="d-inline-block auth-logo">
                  <img src="@/assets/images/logo-j2p.png" alt="" height="50">
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="offset-md-2 col-md-8 col-xs-12">
            <div class="card">
              <div class="card-body form-steps">
                <div class="text-end float-end">
                  <language-selector></language-selector>
                </div>
                <div class="text-center pt-4 pb-4 mb-1">
                  <h5>{{ $t("t-setup-wizard") }}</h5>
                </div>

                <div id="custom-progress-bar" class="progress-nav mb-4">
                  <div class="progress" style="height: 1px;">
                    <div class="progress-bar" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>

                  <ul class="nav nav-pills progress-bar-tab custom-nav" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link rounded-pill active" data-progressbar="custom-progress-bar" id="choose-exchange-step-tab" data-bs-toggle="pill" data-bs-target="#choose-exchange-step" type="button" role="tab" aria-controls="choose-exchange-step" aria-selected="true">1</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="register-exchange-step-tab" data-bs-toggle="pill" data-bs-target="#register-exchange-step" type="button" role="tab" aria-controls="register-exchange-step" aria-selected="false">2</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="add-api-key-tab" data-bs-toggle="pill" data-bs-target="#add-api-key" type="button" role="tab" aria-controls="add-api-key" aria-selected="false">3</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="create-bot-tab" data-bs-toggle="pill" data-bs-target="#create-bot" type="button" role="tab" aria-controls="create-bot" aria-selected="false">4</button>
                    </li>
<!--                    <li class="nav-item" role="presentation">-->
<!--                      <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="subscription-tab" data-bs-toggle="pill" data-bs-target="#subscription-bot" type="button" role="tab" aria-controls="subscription-bot" aria-selected="false">5</button>-->
<!--                    </li>-->
                  </ul>
                </div>

                  <div class="tab-content">
                    <step1></step1>

                    <step2></step2>

                    <step3></step3>

                    <step4></step4>

                    <!-- end tab pane -->
                    <div class="tab-pane fade" id="add-api-key" role="tabpanel" aria-labelledby="add-api-key-tab">
                      <div>
                        <div class="mb-4">
                          <div>
                            <h5 class="mb-1">Securely connect your  account to Damask Trading.</h5>
                            <p class="text-muted">
                              Create your API keys <a href="#">here</a> and input them in the fields below
                            </p>
                            <p class="text-muted">
                              <a href="#">
                                <i class="mdi mdi-file-document"></i>
                                &nbsp;
                                Link to our instruction if needed
                              </a>
                            </p>
                          </div>
                        </div>
                        <div class="text-start">
                          <add-api-key></add-api-key>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<!--  </Layout>-->
</template>
<style>
.ribbon-three::after {
  border-left: 64px solid transparent;
  border-right: 64px solid transparent;
}

.ribbon-three > span {
  width: 130px;
}
</style>
<script>
import LanguageSelector from "@/components/language-selector";
import addApiKey from "@/pages/api-key-form-modal";
import step1 from "@/pages/setup-wizard/step1";
import step2 from "@/pages/setup-wizard/step2";
import step3 from "@/pages/setup-wizard/step3";
import step4 from "@/pages/setup-wizard/step4";
// import ExchangeConnection from "@/state/entities/exchangeConnection";
// import Bot from "@/state/entities/bot";


export default {
  data() {
    return {

    };
  },
  components: {
    LanguageSelector,
    addApiKey,
    step1,
    step2,
    step3,
    step4
  },
  methods: {
  },
  computed: {

  },
  async beforeMount() {
    // let promise = await Bot.api().get('/bot');
    // console.log(promise.response);
    // if (promise.response.data.length > 0) {
    //   window.location.replace('/dashboard');
    // }
  },
  mounted(){
    document.querySelectorAll(".form-steps").forEach(function (form) {
      // next tab
      form.querySelectorAll(".nexttab").forEach(function (nextButton) {
        let tabEl = form.querySelectorAll('button[data-bs-toggle="pill"]');
        tabEl.forEach(function (item) {
          item.addEventListener('show.bs.tab', function (event) {
            event.target.classList.add('done');
          });
        });
        nextButton.addEventListener("click", function () {
          let nextTab = nextButton.getAttribute('data-nexttab');
          if (nextButton.id === 'step1') {
            console.log(nextButton.id);
            let hasExchange = form.querySelector('input[name="have_exchange"]:checked').value;
            if ('yes' === hasExchange) {
              document.getElementById('add-api-key-tab').click();
              return;
            }
          }
          document.getElementById(nextTab).click();
        });
      });

      //Pervies tab
      form.querySelectorAll(".previestab").forEach(function (prevButton) {

        prevButton.addEventListener("click", function () {
          let prevTab = prevButton.getAttribute('data-previous');
          let totalDone = prevButton.closest("form").querySelectorAll(".custom-nav .done").length;
          for (let i = totalDone - 1; i < totalDone; i++) {
            (prevButton.closest("form").querySelectorAll(".custom-nav .done")[i]) ? prevButton.closest("form").querySelectorAll(".custom-nav .done")[i].classList.remove('done'): '';
          }
          document.getElementById(prevTab).click();
        });
      });

      // Step number click
      let tabButtons = form.querySelectorAll('button[data-bs-toggle="pill"]');
      tabButtons.forEach(function (button, i) {
        button.setAttribute("data-position", i);
        button.addEventListener("click", function () {
          let getProgreebar = button.getAttribute("data-progressbar");
          if (getProgreebar) {
            let totallength = document.getElementById("custom-progress-bar").querySelectorAll("li").length - 1;
            let current = i;
            let percent = (current / totallength) * 100;
            document.getElementById("custom-progress-bar").querySelector('.progress-bar').style.width = percent + "%";
          }
          (form.querySelectorAll(".custom-nav .done").length > 0) ?
              form.querySelectorAll(".custom-nav .done").forEach(function (doneTab) {
                doneTab.classList.remove('done');
              }): '';
          for (let j = 0; j <= i; j++) {
            tabButtons[j].classList.contains('active') ? tabButtons[j].classList.remove('done') : tabButtons[j].classList.add('done');
          }
        });
      });
    });
  }
};
</script>