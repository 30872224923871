<template>
  <div class="tab-pane fade" id="add-api-key" role="tabpanel" aria-labelledby="add-api-key-tab">
    <form>
      <div>
        <div class="mb-4">
          <div>
            <h5 class="mb-1">{{ $t("t-securely-connect-your-account") }}</h5>
            <p class="text-muted">
              {{ $t("t-create-api-key-and-input") }}
            </p>
            <p class="text-muted">
              <a v-if="currentLocale() === 'ru'" target="_blank" href='https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>Как создать апи ключи на Binance</span>
              </a>
              <br>
              <a v-if="currentLocale() === 'en' || currentLocale() === 'pl'" target="_blank" href='https://www.binance.com/ru/support/faq/%D0%BA%D0%B0%D0%BA-%D1%81%D0%BE%D0%B7%D0%B4%D0%B0%D0%B2%D0%B0%D1%82%D1%8C-api-%D0%BA%D0%BB%D1%8E%D1%87%D0%B8-%D0%BD%D0%B0-binance-360002502072'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>How to create api key on binance</span>
              </a>
              <br>
              <a v-if="currentLocale() === 'ru'" target="_blank" href='https://www.bitget.com/api-doc/common/quick-start#:~:text=If%20you%20need%20to%20use,an%20API%20Key%20after%20login.'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>Как создать апи ключи на bitget</span>
              </a>
              <br>
              <a v-if="currentLocale() === 'en' || currentLocale() === 'pl'" target="_blank" href='https://www.bitget.com/api-doc/common/quick-start#:~:text=If%20you%20need%20to%20use,an%20API%20Key%20after%20login.'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>How to create api key on bitget</span>
              </a>
            </p>
          </div>
        </div>
        <div class="text-start">
          <add-api-key></add-api-key>
        </div>
      </div>
      <div class="card-footer text-end mt-3">
      <div class="row">
        <div class="col-6 text-start">
          <button class="btn btn-success btn-md btn-label left ms-auto previestab" type="button" data-previous="register-exchange-step-tab" id="previous_step_2">
            <div class="btn-content">
              <i class="ri-arrow-left-line label-icon align-middle fs-16"></i>
              {{ $t("t-back") }}
            </div>
          </button>
        </div>
        <div class="col-6 text-end">
          <button class="btn btn-success btn-md btn-label right ms-auto nexttab nexttab" type="button" data-nexttab="create-bot-tab" id="step3">
            <div class="btn-content">
              <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
              {{ $t("t-continue") }}
            </div>
          </button>
        </div>
      </div>
    </div>
    </form>
  </div>
</template>

<script>
import {localeComputed} from "@/state/helpers";
import addApiKey from "@/pages/api-key-form-modal";
import ExchangeConnection from "@/state/entities/exchangeConnection";

export default {
  data() {
    return {
    };
  },
  components: {
    ...localeComputed,
    addApiKey
  },
  methods: {
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  computed: {
  },
  mounted() {
  },
  beforeMount() {
    let response =  ExchangeConnection.api().get('/exchange-connection');
    console.log(response.response);
  }
};
</script>